.BlogArticle {
  &__body {
    color: #424242;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #212121;
      font-family: 'Outfit', serif;
      margin: 24px 0;
    }

    img {
      max-width: 100%;
    }

    ol,
    ul {
      margin: 24px 0;
      padding-left: 16px;
    }

    pre {
      margin: 16px 0;
      background-color: #1d3f4c; /* Dark background */
      color: #abb2bf; /* Light, muted text color */
      padding: 16px; /* Space around the content */
      border-radius: 5px; /* Rounded corners */
      font-family: 'Fira Code', Consolas, 'Courier New', monospace; /* Monospace fonts for code */
      font-size: 14px; /* Readable font size */
      overflow-x: auto; /* Horizontal scroll for long lines */
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3); /* Subtle inner shadow */
      white-space: pre-wrap;
    }
  }
}
